import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Icon = ({ name, color, className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    className={cn(
      className,
      { [`fill-white`]: color === "white" },
      { [`fill-grey`]: color === "grey" }
    )}
    { ...rest }
  >
    { name === "instagram" && (
      <path d="M23.5,32H8.5C3.8,32,0,28.2,0,23.5V8.5C0,3.8,3.8,0,8.5,0h15.1C28.2,0,32,3.8,32,8.5v15.1
      C32,28.2,28.2,32,23.5,32z M8.5,1.9c-3.6,0-6.6,3-6.6,6.6v15.1c0,3.6,3,6.6,6.6,6.6h15.1c3.6,0,6.6-3,6.6-6.6V8.5
      c0-3.6-3-6.6-6.6-6.6H8.5z M16,24.5c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5S20.7,24.5,16,24.5z M16,9.4
      c-3.6,0-6.6,3-6.6,6.6s3,6.6,6.6,6.6s6.6-3,6.6-6.6S19.6,9.4,16,9.4z M25.4,6.6c1,0,1.9,0.8,1.9,1.9s-0.8,1.9-1.9,1.9
      c-1,0-1.9-0.8-1.9-1.9S24.4,6.6,25.4,6.6z"/>
    )}
    { name === "arrow" && (
      <path d="M28.8 12.8l-6.3-6.3-3.2 3.2 4.1 4.1H0v4.4h23.4l-4.1 4.1 3.2 3.2 6.3-6.3L32 16z"/>
    )}
    { name === "caret" && (
      <path d="M13.7,22.9L0,9.1l2.3-2.3L16,20.6L29.7,6.9L32,9.1L18.3,22.9l0,0L16,25.1L13.7,22.9z"/>
    )}
    { name === 'close' && (
      <path d="M16.6173 14.1422L2.65061 0.175537L0.617188 2.20897L14.5838 16.1756L0.617412 30.1421L2.65084 32.1755L16.6173 18.2091L30.5835 32.1754L32.6169 30.1419L18.6507 16.1756L32.6171 2.20916L30.5837 0.175725L16.6173 14.1422Z"/>
    )}
    { name === 'chevron-left' && (
      <path d="M8.69403 13.8982L22.2706 0.321633L24.5333 2.58438L10.9568 16.1609L24.5331 29.7373L22.2703 32L6.43116 16.1608L8.6939 13.8981L8.69403 13.8982Z"/>
    )}
    { name === 'chevron-right' && (
      <path d="M23.306 18.1018L9.72941 31.6784L7.46667 29.4156L21.0432 15.8391L7.46691 2.26274L9.72965 5.22643e-07L25.5688 15.8392L23.3061 18.1019L23.306 18.1018Z"/>
    )}
  </svg>
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['black', 'white', 'grey']),
  className: PropTypes.string,
}

Icon.defaultProps = {
  color: 'black',
  className: ''
}

export default Icon
